<template>
  <DialogBase
    :dialogWidth="900"
    :dialogModel="dialogModel"
    @closeDialog="(val) => closeDialog(val)"
    scrollable
  >
    <template v-slot:title>Blogs Management</template>

    <v-card elevation="0" class="ma-0 pa-0">
      <v-card-text class="ma-0 pa-0">
        <v-row class="my-6">
          <v-col cols="4">
            <v-card
              class="fill-height transparent-1"
              @click="showCreateUpdateBlogDialog()"
            >
              <v-card-title>
                Add Blog
                <v-spacer></v-spacer>
                <v-icon color="primary" large> mdi-plus-circle-outline </v-icon>
              </v-card-title>
            </v-card>
          </v-col>
          <template v-for="(blog, index) in blogsList">
            <v-col cols="4" :key="index">
              <v-card
                class="fill-height transparent-1"
                @click="showCreateUpdateBlogDialog(blog)"
              >
                <v-card-text class="text-center">
                  {{ blog.title }}
                </v-card-text>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </v-card-text>
    </v-card>

    <template v-slot:footer>
      <v-btn color="accent" outlined block @click="closeDialog()">Close</v-btn>
    </template>
    <template v-if="createUpdateBlogDialog">
      <createUpdateBlogDialog
        :dialogModel="createUpdateBlogDialog"
        :selectedModel="selectedBlog"
        @closeDialog="closeCreateUpdateBlogDialog()"
        @created="closeCreateUpdateBlogDialog()"
        @refresh="getBlogs()"
      />
    </template>
  </DialogBase>
</template>

<script>
import FormMaker from "@/components/FormMaker";
import DialogBase from "@/components/dialogs/Base";
import createUpdateBlogDialog from "@/components/dialogs/QuotesPortal/createUpdateBlogDialog";
export default {
  components: {
    DialogBase,
    FormMaker,
    createUpdateBlogDialog,
  },
  props: {
    dialogModel: { required: true, type: Boolean },
    selectedModel: { required: true, default: null },
  },
  data() {
    return {
      createUpdateBlogDialog: false,
      selectedBlog: null,
      blogsList: [],
      getBlogsListRes: null,
    };
  },
  mounted() {
    this.getBlogs();
  },
  computed: {},
  methods: {
    closeDialog(val = false) {
      this.$emit("closeDialog", val);
    },
    async showCreateUpdateBlogDialog(selectedBlog = null) {
      if (selectedBlog) {
        await this.getBlog(selectedBlog.url);
      } else {
        this.selectedBlog = null;
      }
      this.createUpdateBlogDialog = true;
    },
    closeCreateUpdateBlogDialog() {
      this.selectedBlog = null;
      this.createUpdateBlogDialog = false;
    },
    async getBlogs() {
      let url = this.baseUrl + `/services/quotes-portal/blog/get-blogs-list`;
      let data = { per_page: 1000 };
      return await this.post(url, data, false).then((data) => {
        if (data == "undefined") return;
        if (data.code == 200) {
          this.blogsList = data?.data?.blogs_list.data;
          this.getBlogsListRes = data?.data?.blogs_list;
          // console.log(JSON.stringify(this.blogsList));
        }
      });
    },
    async getBlog(blog_url) {
      let url = this.baseUrl + `/services/quotes-portal/blog/get-blog`;
      let data = { blog_url };
      return await this.post(url, data, false).then((data) => {
        if (data == "undefined") return;
        if (data.code == 200) {
          this.selectedBlog = data?.data?.blog;
          // console.log(JSON.stringify(this.blogsList));
        }
      });
    },
  },
};
</script>
